import styled from 'styled-components';
import { ProgressBar } from '@campus/components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;

  text-align: center;
  font-size: 12px;
  font-weight: 600;
  background-color: ${(props) => props.$background};
`;
export const ProgressBarContent = styled(ProgressBar) `
  height: 12px;
  flex: 1;
  display: flex;
  min-width: 50px;
  background-color: ${(props) => props.$background};
  box-shadow: 0 0 0px 1px ${(props) => props.theme.colors.gray300} inset;
  border-radius: 2px;
`;
export const ProgressBarItem = styled(ProgressBar) `
  height: 100%;
  border-radius: 0;
  background-color: ${(props) => props.$color} !important;

  :last-child {
    border-radius: 0;
  }
`;
export const PercentageBarItem = styled.div `
  height: 100%;
  width: ${(props) => props.$percentage}%;
  background-color: ${(props) => props.$color ?? props.theme.colors.gray300};

  :last-child {
    border-radius: 0;
  }
`;
export const Header = styled.div `
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 0;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: row;
  }
`;
export const LabelTile = styled.span `
  display: flex;
  flex: 1;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.theme.colors.gray3};
`;
export const ValueTile = styled.span `
  font-size: 12px;
  font-weight: ${(props) => props.theme.weight.bold};
  color: ${(props) => props.$color};

  ${(props) => props.$center && 'flex: 1;'};
`;
