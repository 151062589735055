import {
  EvaluationType,
  EvaluationTypeInput,
  EvaluationTypeDTO
} from './EvaluationType';
import { Rating, RatingInput } from './Rating';
import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface EvaluationMatrixFilter extends PaginatedFilter {
  name?: string;
}

export type EvaluationMatrixInput = {
  name: string;
  description: string;
  evaluationModelType: string;
  evaluationTypes: Array<EvaluationTypeInput>;
  ratings: Array<RatingInput>;
};

export type EvaluationMatrixDTO = {
  Name?: string;
  Description?: string;
  EvaluationModelType?: string;
  EvaluationTypes: Array<EvaluationTypeDTO>;
  Ratings: Array<any>;
};

export class EvaluationMatrix implements FormModel<EvaluationMatrixInput> {
  id?: string;
  name?: string;
  description?: string;
  EvaluationModelType?: string;
  evaluationTypes?: Array<EvaluationType>;
  ratings?: Array<Rating>;

  static fromJson(data: any): EvaluationMatrix {
    const evaluationMatrix = new EvaluationMatrix();

    evaluationMatrix.id = data?.Id;
    evaluationMatrix.name = data?.Name;
    evaluationMatrix.description = data?.Description;
    evaluationMatrix.EvaluationModelType = data?.EvaluationModelType;

    evaluationMatrix.evaluationTypes =
      data?.EvaluationTypes?.map((r) => EvaluationType.fromJson(r)) ?? [];

    const Ratings = data?.Ratings;
    if (Array.isArray(Ratings)) {
      evaluationMatrix.ratings = Ratings.map((r) => Rating.fromJson(r));
    }

    return evaluationMatrix;
  }

  static fromInputToDTO = (
    input: EvaluationMatrixInput
  ): EvaluationMatrixDTO => ({
    Name: input.name,
    Description: input.description,
    EvaluationModelType: input.evaluationModelType,
    EvaluationTypes: input.evaluationTypes?.map(EvaluationType.fromInputToDTO),
    Ratings: input.ratings?.map(Rating.fromInputToDTO)
  });

  toInput = (): EvaluationMatrixInput => ({
    name: this.name,
    description: this.description,
    evaluationModelType: this.EvaluationModelType,
    evaluationTypes: this.evaluationTypes.map((r) => r.toInput()),
    ratings: this.ratings?.map((r) => r?.toInput())
  });
}
