import FormModel from './interfaces/FormModel';

export type DisciplineStageInput = {
  name: string;
  stageId: string;
  evaluationTypeId: string;
  maxWeight: number;
}

export type DisciplineStageDTO = {
  StageId: string;
  EvaluationTypeId: string;  
  MaxWeight: number;
}


export type DisciplineMatrixStageDTO = {
  Ids: Array<string>;
  Stages: Array<DisciplineStageDTO>;
}

export class DisciplineMatrixStage {
  ids: Array<string>;

  stages: Array<DisciplineStage>;

  static fromJson(data: any): DisciplineMatrixStage {
    const disciplineMatrixStage = new DisciplineMatrixStage();
    disciplineMatrixStage.stages = data?.map(item => DisciplineStage.fromJson(item));

    return disciplineMatrixStage;
  }

  static fromInputToDTO = (input: DisciplineMatrixStage): DisciplineMatrixStageDTO => ({
    Ids: input?.ids,
    Stages: input?.stages?.map(item => DisciplineStage.fromInputToDTO(item)),
  })
}

export class DisciplineStage implements FormModel<DisciplineStageInput> {
  name: string;
  stageId: string;
  evaluationTypeId: string;
  maxWeight: number;

  static fromJson(data: any): DisciplineStage {
    const disciplineStage = new DisciplineStage();
    disciplineStage.maxWeight = data?.MaxWeight;
    disciplineStage.evaluationTypeId  = data?.EvaluationType?.Id;
    disciplineStage.stageId = data?.Stage?.Id;
    disciplineStage.name = data?.Stage?.Name + ' - ' + data?.EvaluationType?.Name;
    return disciplineStage;
  }

  toInput(): DisciplineStageInput {
    return {
      name: this.name,
      stageId: this.stageId,
      evaluationTypeId: this.evaluationTypeId,
      maxWeight: this.maxWeight,
    }
  }

  static fromInputToDTO = (input: DisciplineStageInput): DisciplineStageDTO => ({
    StageId: input.stageId,
    EvaluationTypeId: input.evaluationTypeId,
    MaxWeight: input.maxWeight
  })
}