import FormModel from './interfaces/FormModel';

export type RatingInput = {
  id?: string;
  minimumValue: number;
  rating: string;
  description: string;
};

export type RatingInputDTO = {
  Id?: string;
  MinimumValue: number;
  Rating: string;
  Description: string;
};

export class Rating implements FormModel<RatingInput> {
  id?: string;
  minimumValue?: number;
  rating?: string;
  description?: string;

  static fromJson(data: any): Rating {
    const rating = new Rating();

    rating.id = data?.Id;
    rating.minimumValue = data?.MinimumValue;
    rating.rating = data?.Rating;
    rating.description = data?.Description;

    return rating;
  }

  static fromInputToDTO = (input: RatingInput): RatingInputDTO => ({
    Id: input.id,
    MinimumValue: input.minimumValue,
    Rating: input.rating,
    Description: input.description
  });

  toInput = (): RatingInput => ({
    id: this.id,
    minimumValue: this.minimumValue,
    rating: this.rating,
    description: this.description
  });
}
