class DisciplineEvaluation {
    id;
    name;
    shortName;
    evaluationDescription;
    contentCount;
    currentCount;
    weight;
    percentage;
    evaluationTypeWeight;
    color;
    evaluable;
    performance;
    evaluationTypeMode;
    currentRating;
    static fromJson(data) {
        const disciplineEvaluation = new DisciplineEvaluation();
        disciplineEvaluation.id = data.EvaluationTypeId;
        disciplineEvaluation.name = data.EvaluationTypeName;
        disciplineEvaluation.shortName = data.EvaluationTypeShortName;
        disciplineEvaluation.contentCount = data.ContentCount;
        disciplineEvaluation.currentCount = data.CurrentCount;
        disciplineEvaluation.weight = data.Weight;
        disciplineEvaluation.evaluable = data.Evaluable;
        disciplineEvaluation.performance = data.Performance;
        disciplineEvaluation.evaluationTypeMode = data.EvaluationTypeMode;
        disciplineEvaluation.evaluationTypeWeight = data.EvaluationTypeWeight;
        disciplineEvaluation.evaluationDescription = data.EvaluationDescription;
        disciplineEvaluation.percentage = data.Percentage;
        disciplineEvaluation.color = data.EvaluationTypeColor;
        disciplineEvaluation.currentRating = data.CurrentRating;
        return disciplineEvaluation;
    }
}
export default DisciplineEvaluation;
